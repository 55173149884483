import React from "react";
import { View } from "@react-pdf/renderer";
import PreviewContainer from "./PreviewContainer";
import PreviewItem from "./PreviewItem";

const RecipientSection = ({ receiver_account, type, receiver }) => {
  let financialDetails = "";
  const acType = receiver_account.type;
  if (acType === "venmo") {
    financialDetails = (
      <>
        <PreviewItem title={"Venmo ID"} content={receiver_account?.venmo_id} />
      </>
    );
  } else if (acType === "paypal") {
    financialDetails = (
      <>
        <PreviewItem title={"PayPal Email"} content={receiver_account?.email} />
      </>
    );
  } else if (acType === "bank") {
    const {
      name,
      account_no,
      lei_code,
      branch,
      iban,
      swift_code,
      transit_number,
      aba_number,
      address,
      ifsc_code,
    } = receiver_account.bank;
    financialDetails = (
      <>
        <PreviewItem title={"Bank Name"} content={name} />
        <PreviewItem title={"Branch"} content={branch} />{" "}
        <PreviewItem title={"Account Number"} content={account_no} />{" "}
        <PreviewItem title={"LEI Code"} content={lei_code} />{" "}
        <PreviewItem title={"IBAN"} content={iban} />{" "}
        <PreviewItem title={"SWIFT BIC Code"} content={swift_code} />
        <PreviewItem title={"Transit Number"} content={transit_number} />
        <PreviewItem title={"ABA Routing Number"} content={aba_number} />
        <PreviewItem title={"IFSC Code"} content={ifsc_code} />
        <PreviewItem title={"Address"} content={address} />
      </>
    );
  }

  return (
    <>
      <View style={{ marginBottom: "30px" }} wrap={false}>
        <PreviewContainer title={"Recipient Details"}>
          <PreviewItem
            title={"Primary Contact Name"}
            content={receiver?.primary_contact_name}
          />
          <PreviewItem
            title={"Receiver Type"}
            content={receiver?.receiver_type?.display}
          />
          <PreviewItem
            title={"Business Name"}
            content={receiver?.business_name}
          />
          <PreviewItem
            title={"Line of Business"}
            content={receiver?.line_of_business?.display}
          />{" "}
          <PreviewItem
            title={"Corporation Type"}
            content={receiver?.corporate_type?.option}
          />{" "}
          <PreviewItem title={"City"} content={receiver?.city?.name} />
          <PreviewItem
            title={"Province/State"}
            content={receiver?.province?.name}
          />
          <PreviewItem title={"Country"} content={receiver?.country?.name} />
          <PreviewItem
            title={"Primary Contact Designation"}
            content={receiver?.primary_designation}
          />
          <PreviewItem title={"Email"} content={receiver?.email} />
          <PreviewItem
            title={"Primary Contact Number"}
            content={receiver?.primary_contact_no}
          />
          <PreviewItem
            title={"Secondary Contact Number"}
            content={receiver?.secondary_contact_no}
          />
          <PreviewItem
            title={"Country of Operation"}
            content={receiver?.country_of_operation?.name}
          />
          <PreviewItem
            title={"Address Line One"}
            content={receiver?.operating_address_line_1}
          />
          <PreviewItem
            title={"Address Line Two"}
            content={receiver?.operating_address_line_2}
          />{" "}
          <PreviewItem
            title={"Address Line Three"}
            content={receiver?.operating_address_line_3}
          />{" "}
          <PreviewItem
            title={"Operating Province/State"}
            content={receiver?.operating_province?.name}
          />
          <PreviewItem
            title={"Operating City"}
            content={receiver?.operating_city?.name}
          />
          <PreviewItem
            title={"Operating Postal Code"}
            content={receiver?.operating_postal_code}
          />
          <PreviewItem title={"Tax ID"} content={receiver?.tax_id} />
        </PreviewContainer>
      </View>
      <View style={{ marginBottom: "100px" }} wrap={false}>
        <PreviewContainer title={"Financial Details"}>
          {financialDetails}
        </PreviewContainer>
      </View>
    </>
  );
};

export default RecipientSection;
